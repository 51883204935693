import React, { useEffect, useState } from "react"
import MainLayout from "../layouts/MainLayout"
import { Script } from "gatsby"
import { getQueryParams } from "../helpers/getQueryParams"
import { Faction } from "../services/FactionDataService"

export default function Litepaper() {
  const [type, setType] = useState()

  const getEmbedUrl = () => {
    switch (type) {
      case Faction.ECO:
      // Not yet implemented
      case Faction.TECH:
        return "https://online.flippingbook.com/view/767502413/"
      case Faction.INDUSTRIAL:
        return "https://online.flippingbook.com/view/13799920/"
      case Faction.TRADITIONAL:
        return "https://online.flippingbook.com/view/1060720891/"
      default:
        break
    }
  }

  useEffect(() => {
    const params = getQueryParams(window.location.href)
    if (Object.keys(params).length > 0) {
      setType(params.type)
    }
  }, [])

  return (
    <MainLayout navCompact={true}>
      {type ? (
        <div
          style={{
            minHeight: "100vh",
            backgroundColor: "#1f2032",
            paddingTop: "10rem",
          }}
        >
          <a
            href={getEmbedUrl()}
            className="fbo-embed"
            data-fbo-id="59870358fb"
            data-fbo-ratio="3:2"
            data-fbo-lightbox="yes"
            data-fbo-width="100%"
            data-fbo-height="auto"
            data-fbo-version="1"
            style={{ maxWidth: "100%" }}
          >
            Loading...
          </a>
          <Script src="https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=767502413"></Script>
        </div>
      ) : null}
    </MainLayout>
  )
}
